<template>
	<BaseButton
		v-if="hasFilters"
		class="button-secondary reset"
		:click="_onReset"
		:icon="'ui/close.svg'"
	>
		Filter löschen
	</BaseButton>
</template>

<script>
import {mapGetters} from 'vuex';
import {ScrollMixin} from '@/mixins';


export default {
	mixins: [ScrollMixin],
	props: {
		scrollTop: {
			type: Boolean,
			default: true
		}
	},
	computed: {
		...mapGetters(['hasFilters'])
	},
	methods: {
		_onReset() {
			this.$store.dispatch('resetFilters');
			this.scrollTop && this.scroll();
		}
	}
};
</script>
